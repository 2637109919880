@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'Prompt-Regular';
    src: local('Prompt-Regular'), url('./fonts/Prompt/Prompt-Regular.ttf') format('truetype');
    font-weight: normal;
}

/* src/fonts/Prompt/Prompt-SemiBold.ttf */
@font-face {
    font-family: 'Prompt-SemiBold';
    src: local('Prompt-SemiBold'), url('./fonts/Prompt/Prompt-SemiBold.ttf') format('truetype');
    font-weight: bold;
}