:root {
  --amplify-primary-color: #d31c1e;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
  ----amplify-components-authenticator-router-border-color: 'white';
}

[data-amplify-authenticator] [data-amplify-router] {
  border: white !important;
}
[data-amplify-authenticator] {
  height: 100vh;
}
.no-tailwind > * {
  all: revert;
}
.profile {
  display: flex;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
}

profile-border {
  border-top: 1px solid #ccc;
}

.details_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
}
.appointment_grid_child {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 4;
}
.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 300px;
  /* width: 650px; */
  overflow-y: auto;
}
.ck.ck-balloon-panel.ck-balloon-panel_visible {
  display: none !important;
}
.ck.ck-toolbar__items {
  justify-content: space-around;
}

.ck.ck-editor__main > .ck-editor__editable > * {
  all: revert;
}
