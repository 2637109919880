.input-group {
  position: relative;
}
.input-group label {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 16px;
  color: gray;
  padding: 0 5px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 16px;
}
.input-group input {
  padding: 10px;
  border: 1px solid rgb(229, 231, 235);
  width: 100%;
  border-radius: 5px;
  outline: none;
}

.input_focus {
  top: 0 !important;
  background: rgb(31, 41, 55) !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 12px !important;
  background-color: white;
  padding: 0 10px !important;
}
.input_out_focus {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.25);
  padding: 0 5px;
  pointer-events: none;
  transition: 10ms;
  font-size: 16px;
}

.dark_border {
  border: 1px solid black !important;
}
